<template>
	<div class="pageContainer targetPage flexCloumn">
		<el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
			<div class="flexStart flex_1" style="flex-wrap:wrap;">

				<el-button size="small" icon="el-icon-plus" @click="formAdd" type="primary">新增</el-button>

			</div>
		</el-row>
		<div class="dividerBar"></div>

		 <el-table v-tableFit :data="tableData" default-expand-all :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
			height="100%" size="small" stripe border style="width:fit-content;width:100%;">
			<el-table-column type="index" label="序号" width="65"></el-table-column>
			<el-table-column prop="role_name" label="角色名称"></el-table-column>
			<el-table-column prop="role_code" label="角色代码"></el-table-column>
			<el-table-column prop="sort" width="80px" label="排序"></el-table-column>
			<el-table-column fixed="right" label="操作" width="220px">
				<template slot-scope="scope">
					<el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info"
						iconColor="red" @confirm="handleDel(scope.row)" placement="left" title="是否确定删除？">
						<el-tag class="pointer" slot="reference" size="mini" type="danger">删除</el-tag>
					</el-popconfirm>
					<el-tag class="pointer ml10" type="primary" size="mini" @click="handleModify(scope.row)">修改</el-tag>
					<el-tag class="pointer ml10" type="success" size="mini" @click="showAuth(scope.row)">菜单配置</el-tag>
					<!-- <el-tag class="pointer ml10" type="success" size="mini" @click="showModule(scope.row)">模块配置</el-tag>
					<el-tag class="pointer ml10" type="success" size="mini" @click="showZiduan(scope.row)">可下载字段</el-tag> -->
				</template>
			</el-table-column>
		</el-table>


		<el-dialog :title="modalTitle" :visible.sync="isShowModal" :close-on-click-modal="false" :destroy-on-close="true"
			custom-class="cus_dialog">
			<el-form :model="formData" ref="ruleForm" label-width="120px" :rules="rules"
				style="max-height: 450px;overflow-y: auto;min-height: 300px;">

				<el-form-item label="角色名称" prop="role_name">
					<el-input v-model.trim="formData.role_name" style="width: 50%" />
				</el-form-item>
				<el-form-item label="角色代码" prop="role_code">
					<el-input v-model.trim="formData.role_code" style="width: 50%" />
				</el-form-item>
				<el-form-item label="排序号" prop="sort">
					<el-input-number :min="0" v-model.trim="formData.sort" placeholder="请输入排序号(数字)"
						controls-position="right" />
				</el-form-item>
			</el-form>
			<el-row>
				<el-col :span="22" style="text-align: right;">
					<el-button size="small" type="default" @click="isShowModal = false">取消</el-button>
					<el-button size="small" type="primary" @click="submitForm('ruleForm')">保存</el-button>
				</el-col>
			</el-row>
		</el-dialog>


		<el-dialog title="角色菜单" :visible.sync="dialogAuth" :close-on-click-modal="false" :destroy-on-close="true"
			width="400px" top="60px" custom-class="cus_dialog">
			<el-tree ref="authTree" :props="{ children: 'children', label: 'title' }" node-key="id" :data="authData"
				:default-expand-all="true" show-checkbox>

			</el-tree>

			<div slot="footer" style="text-align: right; padding: 10px; margin-top: 10px; border-top: 1px solid #ccc;">
				<el-button type="primary" icon="el-icon-s-claim" size="small" @click="saveAuthMenu">保存</el-button>
			</div>
		</el-dialog>

		<el-dialog title="角色模块" :visible.sync="dialogModule" :close-on-click-modal="false" :destroy-on-close="true"
			width="400px" top="60px" custom-class="cus_dialog">
			<el-tree ref="moduleTree" :props="{ children: 'children', label: 'name' }" node-key="id" :data="modulesData"
				:default-expand-all="true" show-checkbox>

			</el-tree>

			<div slot="footer" style="text-align: right; padding: 10px; margin-top: 10px; border-top: 1px solid #ccc;">
				<el-button type="primary" icon="el-icon-s-claim" size="small" @click="saveModules">保存</el-button>
			</div>
		</el-dialog>
		
	</div>
</template>
<script>
import api from '@/api/api';

export default {
	name: 'deptRole',
	components:{},
	data() {
		return {
			visible: false,
			isShowModal: false,
			dialogModule: false,
			dialogAuth: false,
			zdDialog:false,
			modalTitle: '添加',
			formData: {},
			firstCascaderProps: {
				label: 'dept_name',
				value: 'id',
				children: 'children',
				emitPath: false,
				checkStrictly: true
			},
			//table
			tabMapOptions: [{
				label: '系统角色',
				key: 'CN'
			},],
			activeName: 'CN',
			tableData: [],
			rules: {
				role_name: [{
					required: true,
					message: '请输入角色名称',
					trigger: 'blur'
				}],
				role_code: [{
					required: true,
					message: '请输入角色代码',
					trigger: 'blur'
				},],
			},
			isShowMenu: false,
			menuTitle: '',
			menuIds: '',
			roleId: null,

			treeData: [],
			treeProps: {
				children: 'children',
				label: 'dept_name'
			},
			authData: [],
			modulesData: [],
			activeItem: null,
			modulesItem: null
		}
	},
	watch: {
		'formData.dept_id'() {
			if (this.$refs.firstCascader) {
				this.$refs.firstCascader.dropDownVisible = false; //监听值发生变化就关闭它
			}
		}
	},
	mounted() {
		this.getList();

		this.getAuthList();
		this.getModuleList();
	},
	methods: {

		getList() {
			this.$http.post("/api/sys_role_list", {
				pagesize: 100
			}).then(res => {
				this.tableData = res.data.data
			})
		},
		formAdd() {
			this.isShowModal = true;
			this.formData = {
				sort: 1,
			}
			this.modalTitle = '添加'
		},
		handleModify(row) {
			this.isShowModal = true;
			this.modalTitle = '修改';
			this.formData = row
		},
		handleDel(row) {
			console.log(row)
			this.$http.post(api.roleDel, {
				id: row.id
			}).then(res => {
				this.$message.success('删除成功')
				this.getList();
			})
		},
		handleCascaderChange() {

		},
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.$http.post("/api/sys_role_edit", this.formData).then(res => {
						this.$message.success('保存成功')
						this.getList();
						this.isShowModal = false;
						return
						if (res.code == 200) {
							this.$message.success(res.msg)
							this.getList();
							this.isShowModal = false;
						} else {
							this.$message.error(res.msg)
						}
					})
				}
			})
		},
		getAuthList() {
			let _this = this
			this.$http.post(api.menuList).then(res => {
				_this.authData = res.data
			})
		},
		getModuleList() {
			let _this = this;
			this.$http.post("/api/sys_module_list").then((res) => {
				console.log(res.data);
				for (let tree of res.data) {
					delete tree.id;
					tree.name = tree.group_name;
					for (let child of tree.children) {
						child.name = child.m_name;
					}
				}

				_this.modulesData = res.data;
			});
		},
		showAuth(item) {
			this.activeItem = item;
			this.dialogAuth = true;
			this.getAuthMenu()
		},
		showModule(item) {
			this.modulesItem = item;
			this.dialogModule = true;
			this.getModule();
		},
		getModule() {
			this.$http
				.post("/api/sys_module_role_list", {
					ktype: "role_id",
					keyword: this.modulesItem.id,
				})
				.then((res) => {
					let kys = [];
					for (let a of res.data) {
						kys.push(a.m_id);
					}
					this.$nextTick(() => {
						this.$refs["moduleTree"].setCheckedKeys(kys);
					});
				});
		},
		getAuthMenu() {
			this.$http.post("/api/get_role_menu", {
				role_id: this.activeItem.id
			}).then(res => {
				let kys = [];
				for (let a of res.data) {


					if (a.children && a.children.length > 0) {
						for (let b of a.children) {

							if (b.children && b.children.length > 0) {
								for (let c of b.children) {
									kys.push(c.menu_id)
								}
							} else {
								kys.push(b.menu_id)
							}
						}
					} else {
						kys.push(a.menu_id)
					}

				}


				this.$nextTick(() => {
					this.$refs["authTree"].setCheckedKeys(kys)

				})

			})
		},
		saveAuthMenu() {
			let menus = this.$refs["authTree"].getCheckedNodes(false, true).map(e => {
				return e.id
			}).join(",")

			this.$http.post("/api/set_role_menu", {
				role_id: this.activeItem.id,
				menu_id: menus
			}).then(res => {
				this.$message.success("保存成功")
				this.dialogAuth = false
			})
		},
		saveModules() {
			let menus = this.$refs["moduleTree"].getCheckedNodes(false, true).map(e => {
				return e.id
			}).join(",")

			this.$http.post("/api/sys_module_role_edit", {
				role_id: this.modulesItem.id,
				m_ids: menus,
			}).then(res => {
				this.$message.success("保存成功")
				this.dialogModule = false
			})
		},
		showZiduan(e){this.zdDialog=e.id}
	}
}
</script>

<style scoped lang="less" type="text/less">
.roleBox {
	height: 100%;

	.el-row,
	.el-row .el-col {
		height: 100%;
	}

	.el-tabs {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	.el-tabs__content {
		flex: 1;

		.el-tab-pane {
			height: 100%;
		}
	}

	.treeContainer {
		height: 100%;
		background-color: #fff;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
		border: 1px solid rgba(220, 223, 230, 0.6);

		.treeTitle {
			height: 40px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			color: #409eff;
			padding-left: 6px;
		}
	}
}
</style>
